.form-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.decorated-list {
  list-style-type: none;
  counter-reset: item;
}

.decorated-list li {
  position: relative;
  padding-left: 30px;
  margin-bottom: 10px;
}

.decorated-list li:before {
  content: counter(item);
  counter-increment: item;
  position: absolute;
  left: 0;
  top: 0;
  color: #000;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  text-align: center;
  line-height: 20px;
}

/* ScrollableContainer.css */

.scroll-container {
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

.scroll-container::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: transparent;
}
